<template>
  <div>
    <el-row class="dash-board">
      <!-- <el-col :span="6">
        <p class="in2">学员姓名:</p>
        <el-input style="width: 200px" class="in3" placeholder="输入姓名" v-model="stuName"></el-input>
      </el-col> -->
      <el-col :span="4" style="display: flex;align-items: center;margin-left: 10px;">
        <!-- <el-button type="primary" @click="getOne" v-permission="['correction:select']">查询</el-button>
        <el-button type="warning" @click="reset" class="">重置</el-button> -->
        <el-button class="el-but2" type="primary" @click="go()" >返回</el-button>
      </el-col>
    </el-row>
    <el-row class="bg">
      <el-table
        :data="tableData"
        style="width: 100%;">
        <el-table-column
          label="学员姓名"
          align="center"
          width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.stuName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="联系电话"
          align="center"
          width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.tel }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="职务"
          align="center"
          width="200">
          <template slot-scope="scope">
            <span>{{ scope.row.duty }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="已批改作业份数"
          align="center"
          width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.modify }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="未批改作业份数"
          align="center"
          width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.notModify }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="seeBt(scope.row)">查 看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top:20px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-size="pageSize"
        layout="total,sizes,  prev, pager, next, jumper"
        :total="total"
        class="pag">
      </el-pagination>
    </el-row>
  </div>
</template>

<script>
  import { findStuWork } from '@/api/training.js'
  export default {
    name: 'task',
    data () {
      return {
        classData: [], // 存储班级列表信息
        stuId: '',
        classId: '',
        className: '',
        stuName: '',
        tableData: [],
        details: {},
        centerDialogVisible: false,
        centerDialogVisibleTwo: false,
        form: {
          stuId: '',
          wordGrade: ''
        },
        total: 0,
        pageNum: 1,
        pageSize: 10,
        rules: {
          wordGrade: [
            { required: true, message: '请输入分数', trigger: 'blur' },
            { min: 1, max: 3, message: '长度在 3 到 5 个字符', trigger: 'blur' }
          ]
        }
      }
    },
    mounted () {
      // 钩子函数
      this.getOne()
    },
    methods: {
      // 初始化列表数据
      getOne () {
        const query = {
          classId: this.$route.query.id,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        findStuWork(query).then(resp => {
          if (resp.data.code === '200') {
            this.tableData = resp.data.data.rows //  接收返回的数据
            this.total = resp.data.data.total // 查询总条数
          }
          console.log(this.tableData)
        })
      },
      // 查看学员
      seeBt(row) {
        this.$router.push({ path: '/findSubmitWork', query: { id: this.$route.query.id, stuId: row.id } })
      },
      // 重置
      // reset() {
      //   this.stuName = ''
      // },
      // 返回上一个页面
      go() {
         this.$router.go(-1)
      },
      // 分页
      handleSizeChange(e) {
        this.pageSize = e
        this.getOne()
      },
      handleCurrentChange(e) {
        this.pageNum = e
        this.getOne()
      }
    }
  }
</script>

<style scoped>
  .dash-board {
    display: flex;
    background-color: white;
    height: 60px;
    padding: 10px 0;
  }
  .bg{margin-top: 15px; padding: 20px; background-color: white;}
  .in1{font-size: 13px; position: absolute; top: 10px; left: 17px}
  .in2{font-size: 13px; position: absolute; top:10px; left: 17px}
  .in3{width: 160px; position: absolute; top: 14px;left: 90px}
  .inquire{ margin-right: 20px;}
  .spani{color: #2c2fd6}
  .e-inps{position: absolute; left: 100px; top: 14px}
  .row-bg{ padding: 10px 0px;}
  .row-text{line-height: 24px;}
  a{ width: 110px; line-height: 28px; display: inline-block; border-radius:4px;
    color: #FFF; background-color: #409EFF; border-color: #409EFF;text-align:center;
    font-size:12px; text-decoration:none; cursor:pointer;}
  a:hover{color: #FFF; background-color: #70b3f9; border-color: #70b3f9; text-decoration:none; }
</style>
